let VideoEditor = {
    version: "9.5.1.383",
    releaseDD: "05",
    releaseMM: "08",
    releaseYYYY: "2021",
    size: "170.93",
    platforms: "Windows 10, 8.1, 8, 7, XP, 2003, Vista (no Mac OS/ Linux support)"
};

let VideoReMaker = {
    version: "6.5.1.254",
    releaseDD: "05",
    releaseMM: "08",
    releaseYYYY: "2021",
    size: "59.35",
    platforms: "Windows 10, 8.1, 8, 7, XP, 2003, Vista (no Mac OS/ Linux support)"
};

let VideoConverter = {
    version: "12.2.1.684",
    releaseDD: "05",
    releaseMM: "08",
    releaseYYYY: "2021",
    size: "80.98",
    platforms: "Windows 10, 8.1, 8, 7, XP, 2003, Vista (no Mac OS/ Linux support)"
};

let MediaPlayer = {
    version: "5.3.1.146",
    releaseDD: "05",
    releaseMM: "08",
    releaseYYYY: "2021",
    size: "105.64",
    platforms: "Windows 10, 8.1, 8, 7, XP, 2003, Vista (no Mac OS/ Linux support)"
};

let AudioEditor = {
    version: "10.1.1.558",
    releaseDD: "05",
    releaseMM: "08",
    releaseYYYY: "2021",
    size: "47.30",
    platforms: "Windows 10, 8.1, 8, 7, XP, 2003, Vista (no Mac OS/ Linux support)"
};

let AudioConverter = {
    version: "10.1.1.622",
    releaseDD: "05",
    releaseMM: "08",
    releaseYYYY: "2021",
    size: "49.09",
    platforms: "Windows 10, 8.1, 8, 7, XP, 2003, Vista (no Mac OS/ Linux support)"
};

export {VideoEditor, VideoReMaker, VideoConverter, MediaPlayer, AudioEditor, AudioConverter};